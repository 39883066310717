// Rany

@font-face {
  font-family: 'Rany';
  src: local('Rany Light'), local('Rany-Light'),
  url('../fonts/Rany/Rany-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Rany';
  src: local('Rany'), local('Rany'),
  url('../fonts/Rany/Rany.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Rany';
  src: local('Rany Medium'), local('Rany-Medium'),
  url('../fonts/Rany/Rany-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Rany';
  src: local('Rany Bold'), local('Rany-Bold'),
  url('../fonts/Rany/Rany-Bold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

// WorkSans

@font-face {
  font-family: 'WorkSans';
  src: local('WorkSans Extra Light'), local('WorkSans-ExtraLight'),
  url('../fonts/WorkSans/WorkSans-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'WorkSans';
  src: url('../fonts/WorkSans/WorkSans-Light.eot');
  src: local('WorkSans Light'), local('WorkSans-Light'),
    url('../fonts/WorkSans/WorkSans-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/WorkSans/WorkSans-Light.woff2') format('woff2'),
    url('../fonts/WorkSans/WorkSans-Light.woff') format('woff'),
    url('../fonts/WorkSans/WorkSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'WorkSans';
  src: url('../fonts/WorkSans/WorkSans-Regular.eot');
  src: local('WorkSans Regular'), local('WorkSans-Regular'),
    url('../fonts/WorkSans/WorkSans-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/WorkSans/WorkSans-Regular.woff2') format('woff2'),
    url('../fonts/WorkSans/WorkSans-Regular.woff') format('woff'),
    url('../fonts/WorkSans/WorkSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'WorkSans';
  src: url('../fonts/WorkSans/WorkSans-Medium.eot');
  src: local('WorkSans Medium'), local('WorkSans-Medium'),
    url('../fonts/WorkSans/WorkSans-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/WorkSans/WorkSans-Medium.woff2') format('woff2'),
    url('../fonts/WorkSans/WorkSans-Medium.woff') format('woff'),
    url('../fonts/WorkSans/WorkSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'WorkSans';
  src: url('../fonts/WorkSans/WorkSans-SemiBold.eot');
  src: local('WorkSans SemiBold'), local('WorkSans-SemiBold'),
    url('../fonts/WorkSans/WorkSans-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/WorkSans/WorkSans-SemiBold.woff2') format('woff2'),
    url('../fonts/WorkSans/WorkSans-SemiBold.woff') format('woff'),
    url('../fonts/WorkSans/WorkSans-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
