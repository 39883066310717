$step: 25;
$interval: 4;

/* MARGIN-TOP
--------------------------------- */
@for $i from 0 through $step {
  .m-t-#{$i*$interval} {
    margin-top: #{$i * $interval}px !important;
  }
}

/* MARGIN-RIGHT
--------------------------------- */
@for $i from 0 through $step {
  .m-r-#{$i*$interval} {
    margin-right: #{$i * $interval}px !important;
  }
}

/* MARGIN-BOTTOM
--------------------------------- */
@for $i from 0 through $step {
  .m-b-#{$i*$interval} {
    margin-bottom: #{$i * $interval}px !important;
  }
}

/* MARGIN-LEFT
--------------------------------- */
@for $i from 0 through $step {
  .m-l-#{$i*$interval} {
    margin-left: #{$i * $interval}px !important;
  }
}

/* PADDING-TOP
--------------------------------- */
@for $i from 0 through $step {
  .p-t-#{$i*$interval} {
    padding-top: #{$i * $interval}px !important;
  }
}

/* PADDING-RIGHT
--------------------------------- */
@for $i from 0 through $step {
  .p-r-#{$i*$interval} {
    padding-right: #{$i * $interval}px !important;
  }
}

/* PADDING-BOTTOM
--------------------------------- */
@for $i from 0 through $step {
  .p-b-#{$i*$interval} {
    padding-bottom: #{$i * $interval}px !important;
  }
}

/* PADDING-LEFT
--------------------------------- */
@for $i from 0 through $step {
  .p-l-#{$i*$interval} {
    padding-left: #{$i * $interval}px !important;
  }
}

/* PADDING-HORIZONTAL
--------------------------------- */
@for $i from 0 through $step {
  .p-h-#{$i*$interval} {
    padding-left: #{$i * $interval}px !important;
    padding-right: #{$i * $interval}px !important;
  }
}

/* PADDING-VERTICAL
--------------------------------- */
@for $i from 0 through $step {
  .p-v-#{$i*$interval} {
    padding-top: #{$i * $interval}px !important;
    padding-bottom: #{$i * $interval}px !important;
  }
}

/* Other Space add THIS !!!
--------------------------------- */
.m-t-a {
  margin-top: auto !important;
}
.m-r-a {
  margin-right: auto !important;
}
.m-b-a {
  margin-bottom: auto !important;
}
.m-l-a {
  margin-left: auto !important;
}
.m-h-a {
  margin-right: auto !important;
  margin-left: auto !important;
}

@for $i from 0 through 8 {
  .p-#{$i*$interval} {
    padding: #{$i*$interval}px !important;
  }

  .m-#{$i*$interval} {
    margin: #{$i*$interval}px !important;
  }
}
