footer {
  margin: 100px 12px;
  display: flex;
  justify-content: center;
  text-align: center;
  gap: 16px;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 38px;
    height: 38px;
  }

  img {
    width: 20px;
  }
}